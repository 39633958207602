namespace Modals
{
    export interface ShowModalOptions
    {
        onShown?: ($modal: JQuery) => void;
        requestOptions?: Site.RequestOptions;
        $modal?: Page.GetJQueryOptions | JQuery | string;
        onHide?: ($modal: JQuery) => void;
    }

    export const show = (options: ShowModalOptions): void =>
    {
        let $modal: JQuery = null;
        let destroy: boolean = false;

        if (options.$modal)
        { 
            if (typeof options.$modal === "string")
            {
                $modal = $(<string>options.$modal).appendTo(document.body);
                destroy = true;
            }
            else
            {
                $modal = getJQuery(<Page.GetJQueryOptions | JQuery>options.$modal);
            }
            showModal();
        }
        else if (options.requestOptions)
        {
            options.requestOptions.onSuccess = onSuccess;
            destroy = true;
            Site.request(options.requestOptions);
        }

        function onSuccess(result: string)
        {
            $modal = $(result).appendTo(document.body);
            showModal();
        }

        function showModal()
        {
            $modal.modal('show');

            if (!$modal.data("hidden"))
            {
                $modal.on('hide.bs.modal', onHide);


            }
            $modal.data("hidden", false);

            if (options.onShown)
            {
                options.onShown($modal);
            }

            function onHide()
            {
                if (!$modal.data("hidden"))
                {
                    if (destroy)
                    {
                        $modal.remove();
                    }
                }

                if (options.onHide)
                {
                    options.onHide($modal);
                }
            }
        }
    }

    export const close = ($modal: JQuery): void =>
    {
        $modal.modal('hide');
    }

    export const hide = ($modal: JQuery): void =>
    {
        $modal.data("hidden", true);
        $modal.modal('hide');
    }
}